body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  font-family: "Montserrat", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #f9f9fa !important;
}
p,
span,
a,
input,
label,
th,
tr,
td,
button,
li {
  font-family: "Montserrat", sans-serif !important;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Montserrat", sans-serif !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* montserrat-regular - latin */
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  src: url("../src/font/Montserrat-Regular.ttf"); /* IE9 Compat Modes */
}

@font-face {
  font-family: "Montserrat";
  font-style: Italic;
  font-weight: 400;
  src: url("../src/font/Montserrat-Italic.ttf");
}

@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  src: url("../src/font/Montserrat-Medium.ttf");
}

@font-face {
  font-family: "Montserrat";
  font-style: Italic;
  font-weight: 500;
  src: url("../src/font/Montserrat-Medium.ttf");
}

@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  src: url("../src/font/Montserrat-SemiBold.ttf");
}

@font-face {
  font-family: "Montserrat";
  font-style: Italic;
  font-weight: 600;
  src: url("../src/font/Montserrat-SemiBoldItalic.ttf");
}

@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  src: url("../src/font/Montserrat-Bold.ttf");
}

@font-face {
  font-family: "Montserrat";
  font-style: Italic;
  font-weight: 700;
  src: url("../src/font/Montserrat-BoldItalic.ttf");
}

@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 800;
  src: url("../src/font/Montserrat-ExtraBold.ttf");
}

@font-face {
  font-family: "Montserrat";
  font-style: Italic;
  font-weight: 800;
  src: url("../src/font/Montserrat-ExtraBoldItalic.ttf");
}

@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 900;
  src: url("../src/font/Montserrat-Black.ttf");
}

@font-face {
  font-family: "Montserrat";
  font-style: Italic;
  font-weight: 900;
  src: url("../src/font/Montserrat-BlackItalic.ttf");
}

a {
  text-decoration: none;
}

/* table */
.custom-border {
  position: relative;
}
.custom-border:after {
  content: " ";
  position: absolute;
  border-left: 2px #70707045 solid;
  top: 35%;
  right: 0;
  height: 30%;
  margin-top: auto;
  margin-bottom: auto;
}

/* phoneinput */
.PhoneInput {
  padding: 1rem !important;
  border: 1px solid #cdcdcd !important;
  display: flex !important;
  align-items: center;
  border-radius: 5px !important;
}

.PhoneInputInput {
  height: 1.4375em !important;
  background: unset !important;
  border: unset !important;
}
.PhoneInputInput:focus:active:target:hover:visited {
  height: 1.4375em !important;
  background: unset !important;
  outline: 0px !important;
  border: unset !important;
}

.PhoneInputInput:focus-visible {
  height: 1.4375em !important;
  background: unset !important;
  outline: 0px !important;
  border: unset !important;
}
.d-flow {
  display: flow-root;
}

.invoice-line-details .btn-left {
  float: right;
  margin-top: -42px;
  margin-bottom: 10px;
}

.btn-primary.disabled,
.btn-primary:disabled {
  background: wheat !important;
}

.d-flow h5 {
  margin-top: 0;
  margin-bottom: 0.5em;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 500;
}

.css-1ylwp8t-MuiDrawer-docked .MuiDrawer-paper {
  border: none;
}

.css-1q0mz3j-MuiDrawer-docked .MuiDrawer-paper {
  border: none;
}
/* badge */

.badge {
  display: inline-block;
  padding: 8px 16px;
  font-size: 80%;
  font-weight: 500;
  line-height: 1;
  text-align: center;
  letter-spacing: 0.5px;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 5px;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.bg-primary {
  color: #3779ec;
  background-color: #3779ec1a;
}

.bg-success {
  color: #00d97e;
  background-color: #ccf7e5;
}

.bg-danger {
  color: #e63757;
  background-color: #fad7dd;
}

.bg-warning {
  color: #ffa50b;
  background-color: #fff7e1;
}
.bg-secondary {
  color: #f14a4a;
  background-color: #f14a4a25;
}
.bg-info {
  color: #793ed6;
  background-color: #793ed614;
}
.bg-tertiary {
  color: #000000;
  background-color: #e6e6e6;
}
.extract-p {
  color: #39c188;
  font-weight: 400;
  font-size: 12px;
  width: -moz-fit-content;
  width: fit-content;
  float: left;
}
.extract-p span {
  width: 7px;
  height: 11px;
  display: block;
  background: #39c188;
  float: left;
  margin: 3px;
  margin-left: 0;
}
.manual-p {
  color: #dc3e51;
  font-weight: 400;
  font-size: 12px;
  width: -moz-fit-content;
  width: fit-content;
  float: left;
}
.manual-p span {
  width: 7px;
  height: 11px;
  display: block;
  background: #dc3e51;
  float: left;
  margin: 3px;
  margin-left: 2rem;
}
.menusroot a{
  color: unset;
}

.chat-btn {
  /* background: #ff7916;
  background: linear-gradient(to bottom, #ffb401, #ff7916); */
    background: #2B88F0;
    width: 50px;
    height: 45px;
    display: flex;
    align-items: center;
    border-radius: 8px;
    padding: 8px;
    position: fixed;
    bottom: 1rem;
    right: 1rem;
    z-index: 1000;
    cursor: pointer;
}

.chat-btn .count {
  position: absolute;
  background: #333;
  color: #fff;
  padding: 3px;
  border-radius: 40px;
  font-size: 12px;
  letter-spacing: 1px;
  top: -11px;
  right: 0;
  width: 25px;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.notpop-screen {
  width: 400px;
  height: 60%;
  position: fixed;
  z-index: 1001;
  /* right: "1190px"; */
  left: "123px";
  bottom: "2rem";
  box-shadow: -1px 0px 12px #c3c3c3a3;
  background: #F9FAFC;
  overflow: hidden;
  border-radius: 12px;
  display: flex;
  border: 1px solid #DBE5ED;
  flex-direction: column;
}
.chat-screen {
  width: 500px;
  height: 95%;
  position: fixed;
  z-index: 1001;
  right: 0;
  bottom: 0;
  box-shadow: -1px 0px 12px #c3c3c3a3;
  background: #F9FAFC;
  overflow: hidden;
  border-radius: 12px;
  display: flex;
  border: 1px solid #DBE5ED;
  flex-direction: column;
}

.chat-screen .chat-header {
  padding: 20px 20px;
  border-bottom: 2px solid #dbe5ed;
  display: flex;
  align-items: center;
}

.chat-screen .chat-header svg {
  margin-left: auto;
}

.chat-screen .chat-header img {
  float: left;
  width: 50px;
  height: 50px;
  border-radius: 40px;
  object-fit: cover;
}

.chat-screen .chat-header .chat-about {
  float: left;
  padding-left: 10px;
  margin-top: 0px;
}

.chat-screen .chat-header .chat-with {
  font-weight: bold;
  font-size: 16px;
}

.chat-screen .chat-header .chat-num-messages {
  color: #92959e;
}

.chat-screen .chat-header .fa-star {
  float: right;
  color: #d8dadf;
  font-size: 20px;
  margin-top: 12px;
}

/*  */

.chat-screen .chat-history {
  padding: 30px 10px 20px;
  overflow-y: scroll;
  height: 575px;
  position: relative;
  display: contents;
}

.chat-screen .chat-history ul {
  list-style: none;
  margin: 0;
  padding: 0;
  border: 0;
  max-width: 430px;
  margin-top: 10px;
}

.chat-screen .chat-history .message-data {
  margin-bottom: 15px;
}

.align-right {
  text-align: right;
}

.chat-screen .chat-history .message-data-time {
  color: #a8aab1;
  padding-left: 10px;
  margin-right: 10px;
}

.chat-screen .chat-history .my-message {
  background: #a5a5a5;
}

.clearfix:after {
  visibility: hidden;
  display: block;
  font-size: 0;
  content: " ";
  clear: both;
  height: 0;
}

.chat-screen .chat-message {
  padding: 17px;
  position: sticky;
  bottom: 0;
  background: #f2f5f8;
  width: 100%;
}

.chat-screen .chat-message textarea {
  width: 100%;
  border: none;
  padding: 10px 20px;
  margin-bottom: 10px;
  border-radius: 5px;
  resize: none;
}

.chat-screen .chat-message button {
  float: right;
}
/* .actionbtn {
  background-color: #4E4B66;
}  */

.chat-screen .other-message .actionbtn {
  position: absolute;
  width: fit-content;
  border-radius: 39px;
  right: 0;
  padding: 5px;
  display: flex;
  align-items: center;
  opacity: 0;
}

.chat-screen .other-message:hover .actionbtn {
  opacity: 1;
}

.chat-screen .other-message .actionbtn button {
  padding: 0px;
  height: auto;
  background: #4E4B66;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.chat-screen .other-message .actionbtn button+button {
  margin-left: 5px;
}

.chat-screen .other-message .actionbtn button svg {
  width: 18px;
  height: 18px;
}

@media screen and (min-height: 650px) {
  .chat-screen .chat-history {
    height: 66vh;
  }
}

@media screen and (min-height: 800px) {
  .chat-screen .chat-history {
    height: 70vh;
  }
}

@media screen and (min-height: 850px) {
  .chat-screen .chat-history {
    height: 74vh;
  }
}

@media screen and (min-height: 980px) {
  .chat-screen .chat-history {
    height: 76vh;
  }
}
/* .menusroot a:hover{
  color: #EC7C37;
} */
.invoiceddate fieldset {
  border: none;
}
.invoicedatatext fieldset {
  border: none;
}
.css-13ovzzx-MuiInputBase-root-MuiInput-root-MuiSelect-root.Mui-disabled:before {
  border: none !important;
}
/* .css-sghohy-MuiButtonBase-root-MuiButton-root.Mui-disabled{
  border:1px solid #4E4B66!important;
  background-color:#555 !important
}
.css-1rwt2y5-MuiButtonBase-root-MuiButton-root.Mui-disabled{
  border:1px solid #4E4B66!important;
  color:#555 !important
} */
.suppliername fieldset {
  border: none;
}
/* 
.Mui-selected{

  color:yellow
} */


/* Scan */

.scanbtn{
  
    background-color: #EC7C37 !important;
    color: white !important;
    margin: 8px;
    padding: 8px 16px;
    text-transform: none !important;
    
   
}

.scanbtn:hover{
  background-color: #D25D15 !important;
  color: white !important;
}

.scandecor{
  text-align: -webkit-center;
  padding: 40px;
  border: 1px dotted #ec7c37;
  border-width: 4px;
  border-radius: 8px;
   
}
.scanimagecor{
  margin: 1rem;
  
}
 
.pdfview canvas.react-pdf__Page__canvas {
  max-width: 100%;
  width: 100% !important;
    height: 100% !important;
}


/*chat list*/
ul.chatlist-right {
  float: right;
  position: relative;
  padding-left: 0;
}
ul.chatlist-left {
  float: left;
  position: relative;
  padding-left: 0;
}
.chatlist-right .chatimg {
  position: absolute;
  right: 0;
}
.chatlist-left .chatimg {
  position: absolute;
  left: 0;
}
.chatlist-right li.chattext {
  background-color: #AFBBC6;
  float: right;
  color: #fff;
  margin-right: 49px;
  text-align: right;
}
li.chattext {
  margin-bottom: 7px;
  width: fit-content;
  padding: 10px 12px;
  border-radius: 7px;
  font-size: 13px;
  cursor: pointer;
  line-break: anywhere;
}
li.chattext.time {
  background-color: transparent !important;
  color: #AFBBC6 !important;
  padding: 0px 6px;
  margin-right: 49px;
  width: -webkit-fill-available;
}
.chatlist-left li.chattext {
  margin-left: 49px;
  background-color: #fff;
  color: #7B8793;
  text-align: left;
}
.chatlist-left li.chattext.typing {
  margin-left: 0;
  font-size: 12px;
  padding: 5px 10px;
  background-color: #afbbc6;
  color: #fff;
}
ul.chatlist-right {
  margin-bottom: 10px !important;
}
.chatlist-right li.chattext.cmd {
  background: transparent;
  color: #AFBBC6;
  margin-bottom: 0;
  text-align: right;
  font-weight: 700;
  float: unset;
  margin-left: auto;
  padding: 0;
}
.chatlist-left li.chattext.cmd {
  background: transparent;
  color: #AFBBC6;
  margin-bottom: 0;
  text-align: left;
  padding: 0;
  font-weight: 700;
}
button.dropdown-item:hover{
  background-color:#8f8f8f !important;
  color: white !important;
}
button.dropdown-item{
  border: none;
  background: none;
  padding: 5px;
  align-items: left;
  text-align: left;
  display: flex;
 
  }
  .fromchat.dropdown-menu.show{
  transform: translate3d(49.833px, -104.5px, 0px) !important;
  inset: auto auto 0px 0px !important;
  background: #f0f1f5;
  /* margin: -9px; */
  width: 54%;
  border: 1px solid #ccc;
  border-radius: 8px;
  min-height: 200px;
  }
.chatbody {
  /* position: absolute;
  bottom: 10px; */
  margin-top: auto;
  padding: 0px 20px;
  overflow-y: scroll;
  height: inherit;
}
.chatlist-right li.chattext.time {
  margin-left: 50px;
}
input[type=file]::file-selector-button  {
  font-family: "Montserrat", sans-serif !important;
  color: white !important;
  margin: 8px;
  padding: 10px 16px;
  text-transform: none !important;
  background-color: #EC7C37 !important;
 content: 'choose file to import';
 font-weight: 500;
 font-size: 14px;
 border-radius: 5px;
 border:none;
 cursor:pointer;
 text-align-last: end;
 color: transparent;
 
}

.sviewpdf canvas.react-pdf__Page__canvas {
  width: 800px !important;
  height: auto !important;
}

.css-yuzg60-MuiButtonBase-root-MuiPaginationItem-root.Mui-selected {
  border-radius: 0px;
}
.selectusermodel .css-1s2u09g-control {
  min-height: 70px;
}
.css-96yce9 .SnackbarItem-variantSuccess {
  background-color: #0E9F85 !important;
  color: #fff;
}
::-webkit-scrollbar {
  width: 6px;
  height: 7px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  /* background: #DBE5ED; */
  background: #A0A3BD;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.invoice-line-details{
  padding: 15px;
}
.swal2-container.swal2-center.swal2-backdrop-show {
  z-index: 1200;
}
.swal2-title {
  font-size: 25px !important;
  font-family: Georgia, serif;
}
.select{
  font-family: "Montserrat", sans-serif !important;
}
.MuiPaginationItem-root {
  font-weight: 600;
}
input#react-csv-reader-input {
  color: transparent;
  width: 130px
}
.actionBtn {
  border: none;
  background: transparent;
  color: #B4B4B4;
  padding: 6px 5px;
  cursor: pointer;
}
.companyLogo{
  /* height: 70px;
  width: 70px;
  border-radius: 50px;
  object-fit: cover; */  
    /* border-radius: 50px; */
    object-fit: cover;  
    max-width: 200px;
    width: 100%;
    max-height: 60px;
    height: 100%;
}
.supplierList .MuiAutocomplete-endAdornment{

right:1px !important
}
.supplierList .MuiOutlinedInput-root{
  border-radius: 7px;
}

.swal2-container {
  z-index: 10000 !important;
}
.swal2-styled.swal2-cancel{
  background-color: white !important;
    border: 2px solid #ec7c37 !important;
    color: #ec7c37 !important;
}
.swal2-styled.swal2-confirm{
background-color: #ec7c37 !important;
    border: 2px solid #ec7c37 !important;
   
}
.swal2-styled.swal2-default-outline:focus {
  box-shadow: none !important;
}
.labelnotify .MuiFormControlLabel-labelPlacementStart{
  margin:0px
}

.makeStyles-submitdialog.Mui-disabled {
  color: rgba(0, 0, 0, 0.26) !important;
  background-color: rgba(0, 0, 0, 0.12)!important;
  border: 1px solid rgba(0, 0, 0, 0.26) !important;
}
.makeStyles-uploaddialog.Mui-disabled{
  color: rgba(0, 0, 0, 0.26) !important;
  background-color: rgba(0, 0, 0, 0.12)!important;
  border: 1px solid rgba(0, 0, 0, 0.26) !important;
}
.popuppdf canvas.react-pdf__Page__canvas{
  max-width: 100%;
}
.Mui-disabled.makeStyles-uploaddialog-9{
  background-color: rgba(0, 0, 0, 0.12)!important;;
}
.apexcharts-legend.apexcharts-align-center.apx-legend-position-right{
  text-align: left;
}

.audit-listing .MuiListItemSecondaryAction-root {
  position: unset;
  transform: none;
}

.audit-listing .MuiListItemText-root {
  margin-right: 10px;
}

.audit-listing .MuiTypography-root.MuiTypography-body1 {
  font-size: 14px;
}
.dashboard-export .MuiTabs-flexContainer {
  align-items: center !important;
}
.dialog-title-supplier .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded{
  overflow: hidden !important;
  height: 27rem !important;
  padding: 8px !important;
}